var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"experiment"},[_c('br'),_c('TopNotification',{attrs:{"variant":"has-background-info","responsive":true,"fade-out":false,"message":"They are limited codes and experiments here, because I'm still migrating them here."}}),_c('slither-slider',{attrs:{"options":{
      animationDuration: 2500,
      secondsOnSlide: 6,
      dots: false,
      autoplay: true,
    }}},[_vm._l((_vm.sliderProjects),function(project){return _c('div',{key:project.id,staticClass:"slide"},[_c('div',{staticClass:"overlay"}),_c('img',{attrs:{"src":project.thumbnail || _vm.randomImage(),"alt":project.name}}),_c('div',{staticClass:"info--"},[_c('div',{staticClass:"--title"},[_vm._v(_vm._s(project.name))]),_c('p',[_vm._v(_vm._s(project.description))]),(project.url)?_c('a',{staticClass:"btn-code",attrs:{"href":project.url}},[_vm._v(" explore now ")]):_vm._e()])])}),_c('template',{slot:"previous"},[_c('span')]),_c('template',{slot:"next"},[_c('span')])],2),_c('br'),_vm._m(0),_c('hr'),_c('div',[_vm._v("FEATURED COLLECTIONS")]),_c('br'),_c('FetchPortfolio',{attrs:{"hide-title":""}}),_c('br'),_c('h5',[_vm._v(_vm._s(_vm.countDown)+" experiments and counting...")]),_c('p',[_vm._v(" Experiments are projects that push the boundaries of art, technology, design and culture. Experiments inspire, teach, and delight. ")]),_c('hr'),_c('div',{staticClass:"space-between"},[_c('div',[_vm._v("ALL PROJECTS")]),_c('DropDown',{attrs:{"label":"FILTER"}})],1),_c('br'),_c('div',{ref:"scrollComponent",staticClass:"featured"},_vm._l((_vm.projects),function(n){return _c('div',{key:n,staticClass:"--card small"},[_c('div',{staticClass:"overlay"}),_c('img',{attrs:{"src":`https://picsum.photos/200?${n}`}}),_c('div',{staticClass:"text"},[_c('div',{staticClass:"--title"},[_vm._v(_vm._s(n)+"Experiments with Google")]),_vm._m(1,true)])])}),0),_c('div',{staticClass:"m-auto"},[_c('br'),_c('br'),(_vm.projects.length < _vm.total)?_c('div',{staticClass:"loader position-relative"},[_vm._v(" Loading... ")]):_c('div',{staticClass:"is-size-5 has-text-centered"},[_vm._v(" That's all, nothing else to load. ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" I have created and worked on different amazing projects and experimented on different tools and technologies ranging from Simple Algorithms, to browser extensions, Mobile apps, Hacks, Bots and more. "),_c('br'),_vm._v(" I'm showcasing my projects and experiments here, along with helpful resources, to inspire others. "),_c('br'),_vm._v(" Here are collections of experiments to explore, with new ones added almost every weekend. Have fun. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"--details"},[_vm._v(" Here are collections of experiments to explore. "),_c('br'),_c('br'),_c('a',{staticClass:"btn-code",attrs:{"href":"#"}},[_vm._v("explore now")])])
}]

export { render, staticRenderFns }